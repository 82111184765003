.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.MEnu {
  color: white;
}

.votes .gciNfx .slick-slide img {
  min-height: auto !important;
  margin-bottom: 20px;
}
.homePannel .sc-fvtFIe {
  display: none !important;
}

.card1 {
  width: 250px; /* Adjust the width as needed */
  height: 300px; /* Adjust the height as needed */
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: black;
}
.c-select {
  width: 30%;
}

.pmenu {
  display: flex;
  justify-content: space-between;
}
.pmenu input {
  height: 5vh;
  border-radius: 5px;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}
.catbtn {
  background: rgb(255, 255, 255) !important;
}
/* .catbtn:hover{
    background: beige;
  } */
.show li button {
  font-size: 14px;
  color: #686161;
  font-weight: 300;
  font-family: Helvetica;
}
.p {
  margin-top: 16vh;
  color: #dddddd;
}
.allbtn {
  background: none;
  border: none;
  margin-left: 10px;
  color: #686161;
}

.dropdown-menu .dropdown-submenu {
  display: none;

  left: 100%;
  top: 30px;
  height: 28vh;
}

.modal-content .sc-gFqAkR input,
.modal-content .lbTAxt textarea,
.modal-content .bzfbLT {
  color: black !important;
}

.typography-message,
.hhMfYI input,
.hhMfYI textarea,
.bdxEnl input,
.bdxEnl textarea .sc-cPiKLX .kwqLrF {
  color: black !important;
}
/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:hover::-webkit-outer-spin-button,
input:hover::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-content .sc-gFqAkR input,
.modal-content .lbTAxt textarea,
.modal-content .bzfbLT {
  color: white !important;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover {
  -moz-appearance: textfield;
}
.form-control::placeholder {
  color: #b1b1b1; /* Set the placeholder text color to #b1b1b1 */
}

.alert-dismissible .btn-close {
  padding: 1rem !important;
}
.alert-warning {
  display: flex !important;
  width: auto !important;
  margin: auto !important;
  padding: 0px !important;
}
.hamza {
  height: 90px;
}
.top5 .col-xl-3 {
  padding: 0 !important;
}
.profileCards {
  padding: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
/* Add specific styles for the Onramp container */
.my-custom-container .sn-1nnijjb {
  background-color: black !important; /* Overwrites inline or default background */
}
.sn-1nnijjb {
  background-color: black !important;
}



@media only screen and (min-width: 320px) and (max-width: 475px) {
  .cuETbC .tab-content {
    padding: 0px 10px !important;
  }
}
.ql-tooltip {
  display: none !important;
}
